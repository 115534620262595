<template>
  <el-dialog
      title="添加报销参数"
      width="30%"
      :visible.sync="states"
      :modal-append-to-body="false"
      @close="close">
    <el-form label-width="100px" :model="form" :rules="rules" ref="form">
      <el-row>
        <el-col :span="20" :offset="1">
          <el-form-item label="报销方式" prop="spendPeopleRemark">
            <el-select v-model="form.spendPeopleRemark" placeholder="请选择报销收款方式" style="width: 100%">
              <el-option
                  v-for="item in spendPeopleRemarks"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="20" :offset="1">
          <el-form-item label="报销账户" prop="spendPeopleAccount">
            <el-input v-model="form.spendPeopleAccount" placeholder="请填写报销账户如  “张三”"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20" :offset="1">
          <el-form-item label="报销账号" prop="spendPeopleAccountNumber">
            <el-input v-model="form.spendPeopleAccountNumber" placeholder="请填写报销账号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="20" :offset="1">
          <el-form-item label="所属银行">
            <el-input v-model="form.spendBank" placeholder="请输入账户所在银行"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">提 交</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "spend-method-add",
  created() {
    this.pageInit()
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: true,
    }
  },
  data() {
    return {
      states: this.state,
      spendPeopleRemarks: [{
          value: '微信'
        },
        {
          value: '支付宝'
        },
        {
          value: '银行'
        }],
      form: {
        spendPeopleRemark: "",
        spendPeopleAccount: "",
        spendPeopleAccountNumber: "",
        spendBank: "",
        projectsType: this.$projectsType.category,
      },
      rules: {
        spendPeopleRemark: [{required: true, message: '请选择报销收款方式', trigger: 'blur'}],
        spendPeopleAccount: [{required: true, message: '请输入报销账户', trigger: 'change'}],
        spendPeopleAccountNumber: [{required: true, message: '请输入报销账号', trigger: 'change'}],
      },
      expenditureArray: [],
    }
  },
  methods: {
    pageInit() {
    },
    close() {
      this.$emit("close");
    },
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: 'POST',
            url: '/spendPeople/addSpendPeople',
            params: {
              ...this.form,
              spendPeople: JSON.parse(localStorage.getItem("emp")).id,
              tenantCrop: localStorage.getItem("tenantCrop")
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? "新增收款参数成功" :response.data.msg,
              type: flag? 'success' :'error',
              duration: 1000,
            })
            if (flag) {
              this.close()
            }
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>